<div class="modal-header">
  <h2 ngbAutofocus class="modal-title flex-fill text-center mt-2">Multifactor Authentication</h2>
  <button type="button" class="btn btn-link close-icon" aria-label="Close" (click)="closeModal()">
    <i class="material-icons-round">close</i>
  </button>
</div>
<div *ngIf="isLoading" class="row">
  <div class="col pt-3">
    <div class="col pt-3">
      <div class="spinner-border text-gray" role="status"></div>
      <p class="small text-gray">Loading...</p>
    </div>
  </div>
</div>
<ng-container *ngIf="mfaTechDifficultiesStatus">
  <div class="modal-body pb-0">
    <ui-alert [alertStatus]="'danger'" alertText="We're unable to complete your request at this time. Please try again later."> </ui-alert>
  </div>
</ng-container>
<ng-container *ngIf="showEnrollment">
  <div *ngIf="showFirstModal && !userClickedSetup">
    <div class="modal-body pb-3">
      <div class="custom-modal-body">
        <h3>Getting Started.</h3>
        <p>
          You can set up MFA in just a few steps. If you quit before you've completed all the steps, you will not be enrolled. You will need
          to start over the next time.
        </p>
        <p>Are you ready to set up MFA?</p>
      </div>
    </div>
    <div class="modal-footer justify-content-right">
      <button id="btnCancelMFAProcess" type="button" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
      <button id="btnSetUpMFA" #btnSetUpMFA type="button" class="btn btn-primary" (click)="setUpMFA()">Set Up MFA</button>
    </div>
  </div>
  <div class="modal-body pb-3" *ngIf="(enrolledData$ | async)?.length > 0 || userClickedSetup">
    <div class="custom-modal-body">
      <p>Welcome, {{ patientName }}</p>
      <p>
        Multifactor authentication (MFA) adds an extra layer of security to your account by requiring an additional step to sign in after
        entering your password.
      </p>
      <p>You can select the types of additional steps (factors) you want to use.</p>
      <form [formGroup]="multiFactorAuth" novalidate>
        <div class="pt-1 d-flex flex-row flex-wrap">
          <div class="col-12 col-lg-6 pe-0">
            <ui-select
              controlName="mfaProcess"
              domId="mfaProcess"
              [searchable]="false"
              label="What would you like to do?"
              placeholder="Select an option"
              (handleChange)="handleEnrollmentSelection($event)"
              [items]="enrollmentChoice"
              [useGridDisplay]="true"
            >
            </ui-select>
          </div>
        </div>
        <div class="pt-3 d-flex flex-row flex-wrap" *ngIf="enrollmentSelect">
          <div class="col-12 col-lg-6 pe-0">
            <ui-select
              class="{{ this.multiFactorAuth.get('factorValue')?.value ? 'factorSelected' : 'factorDropdown' }}"
              [searchable]="false"
              controlName="factorValue"
              domId="factorValue"
              label="Choose a factor to {{ this.multiFactorAuth.get('mfaProcess')?.value }}"
              placeholder="Select an option"
              (handleChange)="handleFactorSelection()"
              [items]="factorDropdownList"
              [useGridDisplay]="true"
            >
            </ui-select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer justify-content-right" *ngIf="!isNextDisabled">
    <button id="btnEnrollNext" type="button" class="btn btn-primary" [disabled]="isNextDisabled" (click)="goToNextPage()">Next</button>
  </div>
</ng-container>
<ng-container *ngIf="!showEnrollment">
  <div *ngIf="factorProcessSelection && factorSelection && isPasswordConfirmationRequired">
    <patient-ui-password-confirmation userEmail="{{ userEmail }}"></patient-ui-password-confirmation>
  </div>

  <!-- SMS Enrollment -->
  <div *ngIf="factorProcessSelection === 'enroll' && factorSelection === 'SMS' && !isPasswordConfirmationRequired">
    <patient-ui-sms-enrollment (showEnroll)="updateShowEnrollmentPage()"></patient-ui-sms-enrollment>
  </div>

  <!-- Okta Enrollment -->
  <div *ngIf="factorProcessSelection === 'enroll' && factorSelection === 'OKTA' && !isPasswordConfirmationRequired">
    <patient-ui-okta-enrollment (showEnroll)="updateShowEnrollmentPage()"></patient-ui-okta-enrollment>
  </div>

  <!-- Google Enrollment -->
  <div *ngIf="factorProcessSelection === 'enroll' && factorSelection === 'GOOGLE' && !isPasswordConfirmationRequired">
    <patient-ui-google-enrollment userEmail="{{ userEmail }}"></patient-ui-google-enrollment>
  </div>

  <!-- Unenrollment -->
  <div *ngIf="factorProcessSelection === 'remove' && factorSelection && !isPasswordConfirmationRequired">
    <patient-ui-unenrollment
      factorID="{{ factorID }}"
      factor="{{ factorSelection }}"
      (showEnroll)="updateShowEnrollmentPage()"
      [enrolledDataLength]="factorDropdownList.length"
    ></patient-ui-unenrollment>
  </div>
</ng-container>
