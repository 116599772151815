<div class="modal-body">
  <p>Enter the temporary code generated in your Okta Verify app.</p>
  <label for="oktacode" class="mb-2">Enter Code</label>

  <input
    type="text"
    (keyup)="onInput($event)"
    [(ngModel)]="inputValue"
    maxlength="6"
    class="form-control {{ verifyErrorCount >= 1 ? 'is-invalid' : '' }}"
    id="oktacode"
  />
  <small class="form-text invalid-feedback" [ngClass]="verifyErrorCount >= 1 ? 'd-block' : 'd-none'">
    <i class="material-icons-round icon-sm">error_outline</i>
    <span class="align-self-center ps-1" [innerHTML]="'This is not a valid code; try again'"></span>
  </small>
</div>
<div class="modal-footer justify-content-right">
  <button id="btnBack" type="button" class="btn btn-secondary" [disabled]="isLoading" (click)="showFactorList.emit()">
    Back
  </button>
  <button id="btnContinue" type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="isLoading">
    Continue
  </button>
</div>
