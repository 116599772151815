import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalFocusService {
  focusModal(modalSelector: string, delay: number = 0): void {
    setTimeout(() => {
      const modalElement = document.querySelector(modalSelector) as HTMLElement;
      if (modalElement) {
        modalElement.setAttribute('tabindex', '-1');
        modalElement.focus();
      }
    }, delay);
  }
}
