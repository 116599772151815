<div
  #alertContainer
  class="alert alert-dismissible d-inline-flex"
  [class.w-100]="fullWidth"
  [class.alert-primary]="alertStatus === 'primary'"
  [class.alert-success]="alertStatus === 'success'"
  [class.alert-danger]="alertStatus === 'danger'"
  [class.alert-warning]="alertStatus === 'warning'"
  [class.alert-info]="alertStatus === 'info'"
  role="alert"
>
  <i class="alert-icon material-icons-round">{{ mainIcon }}</i>
  <ng-template #defaultTextTemplate>
    <div class="alert-content" [innerHTML]="alertText"></div>
  </ng-template>
  <ng-container *ngTemplateOutlet="alertTextTemplate ? alertTextTemplate : defaultTextTemplate"> </ng-container>

  <a *ngIf="sideIconIcon" [routerLink]="sideIconLink" queryParamsHandling="preserve" class="action alert-link"
    ><i class="material-icons-round">{{ sideIconIcon }}</i> {{ sideIconText }}</a
  >

  <button *ngIf="showCloseBtn" type="button" class="btn-close" (click)="closeAlert()" aria-label="Close"></button>
</div>
