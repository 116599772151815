<ng-container *ngIf="!showConfirmationScren">
  <div class="modal-body pb-3">
    <h3 ngbAutofocus class="modal-title flex-fill pb-6 head-text-alignment">
      <div class="flex-0">
        <img alt="Labcorp | Patient | MFA" src="assets/images/mfa-icon.svg" class="me-2" />
      </div>
      <div class="flex-1">MFA Now Available in Labcorp Patient</div>
    </h3>
    <div class="custom-modal-body">
      <p>
        The security of your data is very important. Turn on multifactor authentication (MFA) to protect your account from unauthorized
        access. MFA is easy to use and makes your account more secure.
      </p>
    </div>
  </div>
  <div class="modal-footer overflowButton">
    <p class="justify-content-right learn-more-btn-mobile">
      <a class="redirect-link" [href]="helpCenterUrl + 'category/8000'" target="_blank">Learn more about MFA</a>
    </p>
    <button id="doITLaterMFA" type="button" class="btn btn-secondary" (click)="confirmScreen()">Do It Later</button>
    <button id="turnOnMFA" type="button" class="btn btn-primary" (click)="redirectToProfile()">Turn on MFA Now</button>
    <p class="justify-content-right learn-more-btn-desktop">
      <a class="redirect-link" [href]="helpCenterUrl + 'category/8000'" target="_blank">Learn more about MFA</a>
    </p>
  </div>
</ng-container>
<ng-container *ngIf="showConfirmationScren">
  <div class="modal-body pb-3">
    <h3 ngbAutofocus class="modal-title flex-fill pb-6">
      <img alt="Labcorp | Patient | MFA" src="assets/images/mfa-icon.svg" class="me-2" /> Are You Sure?
    </h3>
    <div class="pb-0" *ngIf="mfaTechDifficultiesStatus">
      <ui-alert [alertStatus]="'danger'" alertText="We're unable to complete your request at this time. Please try again later.">
      </ui-alert>
    </div>
    <div class="custom-modal-body">
      <p>MFA helps protect your account against weak or stolen passwords. We strongly encourage you to turn on MFA now.</p>
      <p>Are you sure you want to turn it on later?</p>
    </div>
  </div>
  <div class="modal-footer justify-content-right">
    <p class="justify-content-right learn-more-btn-mobile">
      You can <a class="redirect-link" (click)="redirectToProfile()">visit your profile</a> anytime to turn on MFA.
    </p>
    <button id="turnOnNowMFA" type="button" class="btn btn-secondary" (click)="redirectToProfile()">Turn on Now</button>
    <button id="declinedMFA" type="button" [disabled]="isLoading" class="btn btn-primary" (click)="declineMFA()">Yes, I'm Sure</button>
    <p class="justify-content-right learn-more-btn-desktop">
      You can <a class="redirect-link" (click)="redirectToProfile()">visit your profile</a> anytime to turn on MFA.
    </p>
  </div>
</ng-container>
