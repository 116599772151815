<div class="container-xl">
  <header *ngIf="!loggedIn">
    <div class="row">
      <div class="col">
        <h2 class="mt-6">Email Verification</h2>
      </div>
    </div>
  </header>

  <div class="row ms-0">
    <h5 class="mb-5 ps-0" *ngIf="!loggedIn">
      Please check your email to receive your one-time Verification Code.
    </h5>
    <h5 class="mb-5 ps-0" *ngIf="loggedIn">
      Your Account Has Not Been Verified.
    </h5>
  </div>
  <div class="row ms-0 me-0">
    <ui-alert [alertTextTemplate]="alertTextTemplate" [alertStatus]="alertStatus" [fullWidth]="false" class="ps-0"> </ui-alert>
    <!-- This ng-template is rendered inside of the alert -->
    <ng-template #alertTextTemplate>
      <div class="text overflow-text" [innerHTML]="alertText"></div>
    </ng-template>

    <ui-alert *ngIf="codeResent" [alertTextTemplate]="codeResentTemplate" [alertStatus]="'success'" [fullWidth]="false" class="ps-0">
    </ui-alert>
    <!-- This ng-template is rendered inside of the alert -->
    <ng-template #codeResentTemplate>
      <div class="text" [innerHTML]="'A new verification code was sent to your email.'"></div>
    </ng-template>
  </div>

  <form [formGroup]="verifyCodeForm">
    <div class="row form-group ps-0">
      <div *ngIf="proccessing$ | async; else codeFormTemplate" class="text-center">
        <div class="spinner-border text-gray" role="status"></div>
        <p class="small text-gray">Loading...</p>
      </div>
      <ng-template #codeFormTemplate>
        <div class="row mt-5 w-100">
          <div class="col-12 col-md-3 col-lg-2">
            <label for="verificationCode">Verification Code</label>
            <input
              name="pin"
              type="text"
              maxlength="4"
              class="form-control"
              formControlName="verificationCode"
              id="verificationCode"
              oninput="pin.value = pin.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              [class.is-invalid]="verifyCodeForm.controls.verificationCode.invalid && verifyCodeForm.controls.verificationCode.dirty"
            />
            <ui-form-field-msg
              id="verificationCodesError"
              [validationMessages]="validationMessages.pin"
              controlName="verificationCode"
            ></ui-form-field-msg>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="row ps-0">
      <div class="form-group d-flex flex-column flex-md-row gap-md-3 col-12">
        <ui-button
          id="btnVerify"
          [fullWidth]="true"
          [addClass]="'mt-1 mt-md-0'"
          [buttonText]="'Verify'"
          (handleClick)="onSubmit()"
        ></ui-button>

        <button id="btnResendCode" type="button" class="btn btn-outline-primary mt-4 mt-md-0 ms-md-3" (click)="resendCode()">
          Resend Code
        </button>

        <button
          *ngIf="loggedIn"
          id="btnLogout"
          type="button"
          class="btn btn-outline-primary mt-5 mt-md-0 ms-md-3"
          (click)="signOut($event)"
        >
          Sign Out
        </button>
      </div>
    </div>
  </form>
</div>
